/* Use to add JS functions common on desktop and mobile to avoid duplicates */

// Function get blog articles
function getBlogArticles(id, type, nbArticlesToShow) {
    // Default blog title and description
    let title = Translator.translate('blog_texte_h1');
    let texteSeo = Translator.translate('blog_texte_seo');

    // Change title and description of blog
    if (type == 'cms_subcategory') {
        title = $('#blog_title_' + id).text();
        texteSeo = $('#blog_texte_seo_' + id).text();
    }

    // Change title and description of blog on li click
    $('#cms').find('h1.title').html(title);
    $('#blog_texte_seo').html(texteSeo);

    // Remove/add selected class
    $('.blog-rubriques li').removeClass("selected");
    $(this).addClass("selected");

    //Close filter popup
    $("#cms_subcategory_menu .cms_menu_item.filter").removeClass("open");

    //Reset filters
    $("#date_filter_wrapper li.years").each(function () {
        let elmt = $(this).find("input[type=checkbox]");

        if ($(elmt).is(':checked')) {
            $(elmt).trigger('click');
        }

    });

    // Load cms articles
    ajaxLoadCp(id, type, nbArticlesToShow);
}

/**
 * Function load cms page
 * @param {*} id - CMS caegory ID
 * @param {*} type - Type of CMS : cms_category or cms_subcategory
 * @param {*} nbArticlesToShow - Number of articles to display by default
 */

function ajaxLoadCp(id, type, nbArticlesToShow) {
    $.ajax({
        type: 'post',
        url: path_relative_root + 'ajax_get_list_cms_page.php',
        data: 'type=' + type + '&id=' + id,
        success: function (result) {
            result = JSON.parse(result);

            // génération des prévisu cms page
            if (result.success) {
                $('#cms_subcategory_menu .item.selected').removeClass('selected');
                if (type == "cms_category") {
                    $('#cms_subcategory_menu .see_all').addClass('selected');
                } else {
                    $('#cms_subcategory_menu .see_all').show();
                    $('#csc_menu_' + id).addClass('selected');
                }

                $('#content_previsu_cms_page').remove();
                $('#cms_subcategory_menu item').removeClass('selected');
                $('#cms_subcategory_menu item.see_all').show();
                $('#btnSeeMore').remove();
                $('#cms').append(result.html);
                $('#cms').append('<span class="see_more_txt_cta button primary" id="btnSeeMore" data-categ-id="' + id + '" data-type="' + type + '" data-articles-to-show="' + nbArticlesToShow + '" onClick="seeMoreArticles(this)">' + Translator.translate('see_more') + '</span>');

                // show x articles on page load
                $('#content_previsu_cms_page .previsu_cms_page').hide();
                $('#content_previsu_cms_page .previsu_cms_page:nth-child(n+1):nth-child(-n+' + nbArticlesToShow + ')').show();

                let cmsSubcategory = $('body.cms_subcategory');
                let cmsCategory = $('body.cms_category');
                let cmsElement = $('#cms');

                if (cmsSubcategory.length || cmsCategory.length) {
                    setTimeout(function () {
                        cmsElement.addClass('visible');
                    }, 150);
                }
            }
        },
        complete: function () {
            nbArticlesToShow = $("#content_previsu_cms_page .previsu_cms_page:visible").length;
            let nbArticles = $('#content_previsu_cms_page .previsu_cms_page').length;

            updateTotalElements(nbArticlesToShow, nbArticles);
        }
    });
}

// Update elements counter and show/hide see more button
function updateTotalElements(nbArticlesToShow, nbArticles) {
    let seeMore = $('#cms .see_more_txt_cta');
    let translatedTexteArticle = (nbArticles > 1) ? 'articles' : 'article';

    if (nbArticles <= nbArticlesToShow) {
        seeMore.hide();
    } else {
        seeMore.show();
    }

    $('#nb_article').html(nbArticles + ' ' + Translator.translate(translatedTexteArticle));
}

// Get selected month
function selectAllMonths(obj, year) {
    $('#date_filter_wrapper input[year=' + year + ']').parent().parent().toggle();

    if ($(obj).is(':checked')) {
        $('#date_filter_wrapper input[year=' + year + ']:not(":checked")').trigger('click');
    } else {
        $('#date_filter_wrapper input[year=' + year + ']:checked').trigger('click');
    }
}

// Filter articles
function filterPosts(elmt) {
    let articlesToShowOnFilter = $('#content_previsu_cms_page .previsu_cms_page[hide=false]').length;
    let nbArticlesToShow = $("#cms_element_per_line").val();
    let year = $(elmt).attr('year');

    $('#content_previsu_cms_page .previsu_cms_page').attr('hide', 'true');
    $('#content_previsu_cms_page .previsu_cms_page').css('display', 'none');

    let checkedFilters = $('#date_filter_wrapper input[type=checkbox][name="dates[]"]:checked');

    if (checkedFilters.length) {
        checkedFilters.each(function () {
            let value = $(this).val();

            if (value.length == 7) {
                $('#content_previsu_cms_page .previsu_cms_page.posts_' + value).attr('hide', 'false');
                $('#content_previsu_cms_page .previsu_cms_page').css('display', 'block');

                if ($('#date_filter_wrapper input[type=checkbox]').click(function () {
                    let checked = $(this).is(':checked');
                    if (checked) {
                        $(this).addClass('selected-item');
                    } else {
                        $(this).removeClass('selected-item');
                    }
                })) ;
            }
        });
    } else {
        $('#content_previsu_cms_page .previsu_cms_page').attr('hide', 'false');
        $('#content_previsu_cms_page .previsu_cms_page').css('display', 'block');
    }

    // show x number of articles
    $('#content_previsu_cms_page .previsu_cms_page').hide();
    $('#content_previsu_cms_page .previsu_cms_page[hide=false]:lt(' + nbArticlesToShow + ')').show();

    let countShow = $('#content_previsu_cms_page .previsu_cms_page[hide=false]:visible').length;

    updateTotalElements(countShow, articlesToShowOnFilter);

    setTimeout(function () {
        let hideYear = true;

        $(elmt).parent().parent().parent().find("li").each(function (index) {
            if ($(this).find('input').hasClass('selected-item')) {
                hideYear = false;
            }
        });

        if (hideYear) {
            $(elmt).parent().parent().parent().find('input:checked').trigger('click');
        }
    }, 700);
}

// show x number of articles
function seeMoreArticles(elmt) {
    let id = $(elmt).attr('data-categ-id');
    let type = $(elmt).attr('data-type');
    let articlesToShowOnFilter = 0;
    let nbArticlesToShow = $(elmt).attr('data-articles-to-show');
    let checkedFilters = $('#date_filter_wrapper li ul input[type=checkbox][name="dates[]"]:checked');

    if (checkedFilters.length) {
        let count = 1;

        checkedFilters.each(function () {
            let value = $(this).val();

            $('#content_previsu_cms_page > .posts_' + value).each(function () {
                articlesToShowOnFilter++;

                if (!$(this).is(":visible") && (count <= nbArticlesToShow)) {
                    $(this).show();
                    count++;
                }
            });
        });
    } else {
        articlesToShowOnFilter = $("#content_previsu_cms_page .previsu_cms_page").length;
        $('#content_previsu_cms_page').find('.previsu_cms_page:not(:visible):lt(' + nbArticlesToShow + ')').show();
    }

    let previsuCmsPageVisible = $('#content_previsu_cms_page .previsu_cms_page:visible').length;
    let countPrevisuCmsPage = $('#content_previsu_cms_page .previsu_cms_page').length;

    if (countPrevisuCmsPage === previsuCmsPageVisible) {
        $(elmt).hide();
    }

    updateTotalElements(previsuCmsPageVisible, articlesToShowOnFilter);
}

// DATALAYER FUNCTION
function addToCart(rank) {
}

function removeFromCart(rank) {
}