/**
 * Initializes the calendar for delivery date selection.
 *
 * * @author  Stefane DR  <sdr@wshop.com>
 *
 * @param {string} conveyorId - The ID of the conveyor.
 * @param {boolean} isPreco - Indicates if the delivery is pre-cooked.
 * @param {number} remiseStock - The stock discount.
 */
function initCalendar(conveyorId, isPreco, remiseStock) {
    var inputTarget = $('#delivery-date-' + conveyorId);
    if (inputTarget.length) {
        var dates = inputTarget.attr('enabled-dates');
        var hasUltraFrais = inputTarget.attr('has-ultra-frais');
        var grandeBuche = inputTarget.attr('is-grande-buche');
        var petiteBuche = inputTarget.attr('is-petite-buche');
        var patisserie = inputTarget.attr('is-patisserie');
        var arrayDates = dates.split('|');
        var dateToday = new Date();

        if (inputTarget.attr('conveyor-id')) {
            conveyorId = inputTarget.attr('conveyor-id');
        }

        var storeClosed = inputTarget.attr('store-closed');
        let minDate = dateToday;
        if (conveyorId == '24' && !isPreco) {
            minDate = new Date();
            minDate.setDate(dateToday.getDate() - 1);
        }

        array = checkHourlyRules(arrayDates, dateToday, conveyorId);
        inputTarget.datepicker({
            dateFormat: 'dd/mm/yy',
            minDate: dateToday,
            maxDate: new Date(dateToday.getFullYear(), dateToday.getMonth() + 3, 0),
            altField: '#order_delivery_date',
            altFormat: 'yy-mm-dd',
            firstDay: 1,
            beforeShowDay: function (date) {
                return (conveyorId == '24' && !isPreco)
                ? enablePickupDate(date, storeClosed, conveyorId, petiteBuche,  grandeBuche, patisserie)
                : enableDeliveryDate(date, arrayDates, hasUltraFrais, conveyorId, isPreco, remiseStock, grandeBuche, petiteBuche, patisserie);
            },
        });
    }
}

/**
 * We check if we have availability for this month
 *
 * @author  Youssef Mabrouk  <yma@wshop.com>
 * @since   2022-11-15
 */
function checkAvailibiltyCurrentMonth() {
    var currentDay = new Date();
    var nbrDaysOfCurrentMonth = this.daysInMonth(currentDay.getMonth()+1, currentDay.getFullYear());// "+1" because "getMonth" starts counting at 0, and goes until 11.
    var nbrDaysOfCurrentMonthUnselected = document.getElementsByClassName("ui-datepicker-unselectable").length - document.getElementsByClassName("ui-datepicker-other-month").length;
    var isNoAvailibilty = (nbrDaysOfCurrentMonth == nbrDaysOfCurrentMonthUnselected) ? true : false;
    if (isNoAvailibilty) {
        document.querySelector('.ui-datepicker-next').click();
    }
}

/**
 * We check the number of days of the current month. If we use the "year" param, it's because the specificity of february.
 *
 * @author  Youssef Mabrouk  <yma@wshop.com>
 * @since   2022-11-15
 * @param   {integer}   month
 * @param   {integer}   year
 */
function daysInMonth (month, year) {
    return new Date(year, month, 0).getDate();
}

/**
 * @description Check if the showing date is enabled or not, according to certain rules
 *
 * @author  Stefane DR  <sdr@wshop.com>
 * @since   2022-09-21
 *
 * @param   {string}    date
 * @param   {array}     array
 * @param   {boolean}   ultra_frais
 * @param   {integer}   conveyor_id
 * @param   {boolean}   isPreco
 * @param   {string}    remiseStock
 *
 * @returns {boolean}
 */
function enableDeliveryDate(date, array, ultraFrais, conveyorId = 0, isPreco, remiseStock, grandeBuche = '0', petiteBuche = '0', patisserie = '0') {
    // we recheck the store ID here ta take the shop change in count
    var storeIdNoel = $('#livr_relais').val();
    if (!storeIdNoel) {
        storeIdNoel = $('[name="livr_dom"]').val();
    }
    storeIdNoel = Number(storeIdNoel);
    //specifique date for the christmas pastry
    const { petiteBucheDates, grandeBucheDates } = getBucheDates(storeIdNoel);

    if (isPreco == "1") {
        var datePreco = $.datepicker.parseDate('dd/mm/yy', remiseStock);
        datePreco.setDate(datePreco.getDate() - 1);
        var formattedDatePrecoYear = $.datepicker.formatDate('yy-mm-dd', datePreco);
    }

    var formatDate = $.datepicker.formatDate('mm-dd', date);
    var formatDateYear = $.datepicker.formatDate('yy-mm-dd', date);
    var currentDay = date.getDay();
    conveyorId = (conveyorId != 0) ? conveyorId : $('#conveyor_id').val();
    var arrayFedex = ['36', '54'];
    var arrayDpd = ['52', '53'];
    var specialDates = ['04-09', '04-10', '04-15'];

    // "Saturday" or "Sunday" aren't working days for PM
    if ([6, 0].includes(currentDay) && conveyorId != 24) {
        return false;
    }

    // if it's a pre-order
    if (isPreco == '1') {
        if (formatDateYear < formattedDatePrecoYear) {
            return false;
        }
    }

    // If the selected conveyor is "Chrono Fresh"
    if (conveyorId == '51') {
        var arrayDaysHigh = [0, 1];
        var arrayDaysLow = [0, 1, 2, 4, 6];

        // WP-26451 activation de tous les jours sauf le week-end
        if (formatDate >= '02-01' && formatDate <= '03-31') {
            return [array.indexOf(formatDate) == -1 && array.indexOf(formatDateYear) == -1];
        } else if (formatDate >= '09-01' && formatDate <= '06-30') {
            // if it's the "high season" we don't show : Sundays and Mondays
            if (arrayDaysHigh.includes(currentDay)) {
                return false;
            }
        } else if (formatDate >= '01-01' && formatDate <= '01-31') {
            // for january we show all working days
            if ([0, 6].includes(currentDay)) {
                return false;
            }
        } else if (formatDate >= '07-01' && formatDate <= '08-31') {
            // if it's the "low season" we only show : Wednesdays and Thursdays
            if (arrayDaysLow.includes(currentDay)) {
                return false;
            }
        }

        // between 30/12 and 05/01, all days are disabled
        if (formatDate >= '12-30' || formatDate <= '01-05') {
            return false;
        }

        // until 29/09, the following days are deactivated: Sunday/ Monday/ Saturday
        if (formatDate < '10-06' && formatDate > '01-31' && [0, 1, 2, 6].includes(currentDay) && !specialDates.includes(formatDate)) {
            return false;
        }

    } else if (arrayDpd.includes(conveyorId) || arrayFedex.includes(conveyorId)) {
        // Disable between the 30/12 and the 04/01
        if (formatDate >= '12-30' || formatDate <= '01-04') {
            return false;
        }
    }

    // Condition pour les produits "Ultra frais" Condition for "Ultra frais" products
    if (ultraFrais == '1') {
        var previousDay = formatDate - 1;
        if (currentDay == 1 || array.includes(previousDay)) {
            return false;
        }
    }

    // Deactivate the day following an undeliverable date
    var arrayPreviousDates = [];
    var previousDayInitial = $.datepicker.parseDate('mm-dd', formatDate);
    previousDayInitial.setDate(previousDayInitial.getDate() - 1);
    arrayPreviousDates.push($.datepicker.formatDate('mm-dd', previousDayInitial));
    // SW-16340 - DO NOT DELETE
    // if (['51', '37'].includes(conveyorId)) { 
    //     previousDayInitial.setDate(previousDayInitial.getDate() - 1);
    //     arrayPreviousDates.push($.datepicker.formatDate('mm-dd', previousDayInitial));
    // }

    if (array.some(v => arrayPreviousDates.indexOf(v) !== -1) && !specialDates.includes(formatDate)) {
        return false;
    }

    if (conveyorId == '24') {
        // Conditions for "Petite Buche"
        if (petiteBuche == '1') {
            if (!petiteBucheDates.includes(formatDate)) {
                return false; // Disable if date is not in valid dates
            }
        }

        // Conditions for "Grande Buche"
        if (grandeBuche == '1') {
            if (!grandeBucheDates.includes(formatDate)) {
                return false; // Disable if date is not in valid dates
            }
        }

        // Conditions for Pastry (delivery on D+1 before 12 p.m.)
        if (patisserie == '1') {
            var tomorrow = new Date();
            tomorrow.setDate(date.getDate() + 1);
            var tomorrowFormatted = $.datepicker.formatDate('yy-mm-dd', tomorrow);

            // If the order is placed after 12 p.m., deactivate D+1
            if (new Date().getHours() >= 12 && formatDateYear == tomorrowFormatted) {
                return false;
            }
        }
    }

    // Return array of valid dates
    return [array.indexOf(formatDate) == -1 && array.indexOf(formatDateYear) == -1];
}

/**
 * @description Check hourly rules for PM's conveyors
 *
 * @author  Stefane DR  <sdr@wshop.com>
 * @since   2022-10-24
 *
 * @param {Array}   array
 * @param {string}  date
 * @param {string}  conveyorId
 *
 * @returns {Array}
 */
function checkHourlyRules(array, date, conveyorId)
{
    var hours = date.getHours();
    var arrayChronopost = ['51', '37'];
    var arrayFedex = ['36', '54'];
    var arrayDpd = ['52', '53'];

    // if the selected conveyor is "ChronoFresh" or "ChronoExpress"
    if (arrayChronopost.includes(conveyorId)) {
        // add D+1
        date.setDate(date.getDate() + 1);
        array.push($.datepicker.formatDate('yy-mm-dd', date));
        array = checkWorkingDays(date, array);
        if (hours >= 12) {
            // add D+2
            date.setDate(date.getDate() + 1);
            array.push($.datepicker.formatDate('yy-mm-dd', date));
            array = checkWorkingDays(date, array);
        }
    } else if (arrayFedex.includes(conveyorId) || arrayDpd.includes(conveyorId)) {
        array.push($.datepicker.formatDate('yy-mm-dd', date));
        array = checkWorkingDays(date, array);
        if (hours >= 8) {
            // add D+1
            date.setDate(date.getDate() + 1);
            array.push($.datepicker.formatDate('yy-mm-dd', date));
            array = checkWorkingDays(date, array);
        }
    }
    return array;
}

/**
 * Checks if current day is a working day
 * @author  Stefane DR  <sdr@wshop.com>
 * @since   2022-11-04
 *
 * @param   {Datetime}  date
 * @param   {Array}     array
 * @returns
 */
function checkWorkingDays(date, array)
{
    currentDay = date.getDay();
    while ([6, 0].includes(currentDay)) {
        date.setDate(date.getDate() + 1);
        currentDay = date.getDay();
        array.push($.datepicker.formatDate('yy-mm-dd', date));
    }
    return array;
}

/**
 * Verifies the integrity of a date according to the given rules
 * 
 * @author Stefane DR <sdr@wshop.com>
 *
 * @param {string} date - The date to verify.
 * @param {string} conveyorId - The ID of the conveyor.
 *
 * @returns {boolean}
 */
function verifyDateIntegrity(date, conveyorId) {
    date = new Date(date);
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    if (date < today) {
        return false;
    }
    let inputTarget = $('#delivery-date-' + conveyorId);
    let dates = inputTarget.attr('enabled-dates');
    let hasUltraFrais = inputTarget.attr('has-ultra-frais');
    let arrayDates = dates.split('|');
    let isPreco = inputTarget.attr('is-preco');
    let remiseStock = inputTarget.attr('remise-stock');
    var grandeBuche = inputTarget.attr('is-grande-buche');
    var petiteBuche = inputTarget.attr('is-petite-buche');
    var patisserie = inputTarget.attr('is-patisserie');
    let verifDate = (conveyorId == '24' && !isPreco)
        ? enablePickupDate(date, inputTarget.attr('store-closed'))
        : enableDeliveryDate(date, arrayDates, hasUltraFrais, conveyorId, isPreco, remiseStock, grandeBuche, petiteBuche, patisserie);
    return verifDate;
}

/**
 * Checks if a given date is within the next 10 days and the store isn't closed on that day.
 * 
 * @author Stefane DR <sdr@wshop.com>
 * 
 * @param {Date} date - The date to check.
 * @param {string} storeClosed - The date when the store is closed in ISO string format.
 * 
 * @returns {boolean} Returns true if the date is within the next 10 days and the store isn't closed on that day, otherwise false.
 */
function enablePickupDate(date, storeClosed, conveyorId = false, petiteBuche = '0', grandeBuche = '0', patisserie = '0') {
    let formattedDate = $.datepicker.formatDate('yy-mm-dd', date);
    let today = new Date();
    let formattedTodayDate = $.datepicker.formatDate('yy-mm-dd', today);
    let endPeriod = new Date();
    if (petiteBuche == '1' || grandeBuche == '1') {
        endPeriod.setDate(today.getDate() + 90);
    } else {
        endPeriod.setDate(today.getDate() + 10);
    }
    let closedDay = new Date(storeClosed);

    // Checking if the chosen date is today and the store is closed
    if (formattedDate == formattedTodayDate && today > closedDay) {
        return false; // If the store is closed, turn off today's date
    }

    // Checking the maximum collection period (10 days)
    if (date > endPeriod) {
        return false; // Disable dates beyond 10 days
    }

    // Dates spécifiques pour Petite Bûche et Grande Bûche
    var storeIdNoel = $('#livr_relais').val();
    if (!storeIdNoel) {
        storeIdNoel = $('[name="livr_dom"]').val();
    }
    storeIdNoel = Number(storeIdNoel);
    const { petiteBucheDates, grandeBucheDates } = getBucheDates(storeIdNoel);
    // Date format for comparison with specific dates
    var formatDate = $.datepicker.formatDate('mm-dd', date);
    
    // Initialize a variable for return
    let isValidDate = true;

    // Conditions based on conveyor ID
    if (conveyorId == '24') {
        // Condition for "Petite Buche"
        if (petiteBuche == '1') {
            // If the selected date is not in the valid dates for Petite Bûche
            if (!petiteBucheDates.includes(formatDate)) {
                isValidDate = false; // Disable if the date is not valid for Petite Bûche
            }
        }

        // Condition for "Grande Bûche"
        if (grandeBuche == '1') {
            // If the selected date is not in the valid dates for Grande Bûche
            if (!grandeBucheDates.includes(formatDate)) {
                isValidDate = false; // Disable if the date is not valid for Grande Bûche
            }
        }

        // Condition for pastry
        if (patisserie == '1') {
                let todayDate =  new Date();
                todayDate.setDate(today.getDate());
                let todayFormattedDate = $.datepicker.formatDate('yy-mm-dd', todayDate);
            // Check if today is before 12 p.m
            if (today.getHours() < 12) {
                let tomorrow = new Date();
                tomorrow.setDate(today.getDate() + 1);
                let formattedTomorrowDate = $.datepicker.formatDate('yy-mm-dd', tomorrow);
                // If the selected date is not tomorrow, it is valid
                if (formattedDate != formattedTomorrowDate && formattedDate === todayFormattedDate) {
                    isValidDate = false; // Disable if we try to choose tomorrow
                }
            } else {
                // If after 12 p.m., deactivate tomorrow
                let tomorrow = new Date();
                tomorrow.setDate(today.getDate() + 1);
                let formattedTomorrowDate = $.datepicker.formatDate('yy-mm-dd', tomorrow);
                if (formattedDate == formattedTomorrowDate || formattedDate === todayFormattedDate) {
                    isValidDate = false; // Disable if we try to choose tomorrow after 12 p.m.
                }
            }
        }
    }

    // Return array of valid dates
    return [isValidDate && date <= endPeriod];
}

function getBucheDates(storeId) {
    let petiteBucheDates = [];
    let grandeBucheDates = [];

    if ([45, 42, 54, 48, 44].includes(storeId)) {
        petiteBucheDates = ['12-07', '12-08', '12-13', '12-14', '12-15', '12-20', '12-21', '12-22', '12-23', '12-24', '12-31'];
        grandeBucheDates = ['12-13', '12-14', '12-15', '12-20', '12-21', '12-22', '12-23', '12-24', '12-31'];
    } else if ([13, 60, 38].includes(storeId)) {
        petiteBucheDates = ['12-23', '12-24'];
        grandeBucheDates = ['12-23', '12-24'];
    }

    return { petiteBucheDates, grandeBucheDates };
}
